export const saleTypes = {
    "en": [
    {
        title: 'For Sale',
        heroTitle: 'Sale',
        value: 'sale'
    },
    {
        title: 'For Rent',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    "th": [
    {
        title: 'For Sale',
        heroTitle: 'Sale',
        value: 'sale'
    },
    {
        title: 'For Rent',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    "cn": [
    {
        title: '买',
        heroTitle: 'Sale',
        value: 'sale'
    },
    {
        title: '租',
        heroTitle: 'Rent',
        value: 'rent'
    }],
    "ru": [
        {
            title: 'Купить',
            heroTitle: 'Sale',
            value: 'sale'
        },
        {
            title: 'Снять',
            heroTitle: 'Rent',
            value: 'rent'
        }],
}

export const sellSaleTypes = {
    "en": [
        {
            title: 'Sell',
            value: 'sale'
        },
        {
            title: 'Rent',
            value: 'rent'
        }
    ],
    "th": [
        {
            title: 'ขาย',
            value: 'sale'
        },
        {
            title: 'เช่า',
            value: 'rent'
        }
    ],
    "cn": [
        {
            title: '出卖',
            value: 'sale'
        },
        {
            title: '出租',
            value: 'rent'
        }
    ],
    "ru": [
        {
            title: 'Продать',
            value: 'sale'
        },
        {
            title: 'Снять',
            value: 'rent'
        }
    ],
}

export const categoryTypes = {
    'en': [
    {
        title: 'Condo',
        value: 'condo'
    },
    {
        title: 'Pool Villa',
        value: 'poolvilla'
    },
    {
        title: 'House',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Commercial',
        value: 'commercial'
    }],
    'th': [
    {
        title: 'คอนโด',
        value: 'condo'
    },
    {
        title: 'บ้าน',
        value: 'house'
    },
    {
        title: 'พูลวิลล่า',
        value: 'poolvilla'
    },
    {
        title: 'ที่ดิน',
        value: 'land'
    },
    {
        title: 'เชิงพาณิชย์',
        value: 'commercial'
    }],
    'cn': [
    {
        title: '公寓',
        value: 'condo'
    },
    {
        title: '别墅',
        value: 'house'
    },
    {
        title: '泳池别墅',
        value: 'poolvilla'
    },
    {
        title: '土地',
        value: 'land'
    },
    {
        title: '工业',
        value: 'commercial'
    }]
}
export const heroCategoryTypes = [
    {
        title: 'Villa',
        value: 'house'
    },
    {
        title: 'Land',
        value: 'land'
    },
    {
        title: 'Condo',
        value: 'condo'
    },
    {
        title: 'Commercial',
        value: 'commercial'
    }
]

export const roomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: 'Studio',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: 'สตูดิโอ',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'cn': [
        {
            title: '无限',
            value: '0'
        },
        {
            title: '开间户型',
            value: 'studio'
        },
        {
            title: '一',
            value: '1'
        },
        {
            title: '二',
            value: '2'
        },
        {
            title: '三',
            value: '3'
        },
        {
            title: '四',
            value: '4'
        },
        {
            title: '五和以上',
            value: '5'
        },
    ],
    'ru': [
        {
            title: 'Любой',
            value: '0'
        },
        {
            title: 'Студия',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],

}
export const sortTypes = {
    'en': [{
        title: 'Newest',
        value: 'datedesc'
    },
    {
        title: 'Price Asc',
        value: 'priceasc'
    },
    {
        title: 'Price Desc',
        value: 'pricedesc'
    }],
    'th': [{
        title: 'ใหม่ล่าสุด',
        value: 'datedesc'
    },
    {
        title: 'Price Asc',
        value: 'priceasc'
    },
    {
        title: 'Price Desc',
        value: 'pricedesc'
    }],
    'cn': [{
        title: '最新',
        value: 'datedesc'
    },
    {
        title: 'Price Asc',
        value: 'priceasc'
    },
    {
        title: 'Price Desc',
        value: 'pricedesc'
    }],
    'ru': [{
        title: 'Новейшие',
        value: 'datedesc'
    },
    {
        title: 'Цена по возрастанию',
        value: 'priceasc'
    },
    {
        title: 'Цена по убыванию',
        value: 'pricedesc'
    }],
}

export const roomHouseTypes = {
    "en": [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: '1+',
            value: '1'
        },
        {
            title: '2+',
            value: '2'
        },
        {
            title: '3+',
            value: '3'
        },
        {
            title: '4+',
            value: '4'
        },
    ],
    "th": [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: '1+',
            value: '1'
        },
        {
            title: '2+',
            value: '2'
        },
        {
            title: '3+',
            value: '3'
        },
        {
            title: '4+',
            value: '4'
        },
    ],
    "cn": [
        {
            title: '无限',
            value: '0'
        },
        {
            title: '1+',
            value: '1'
        },
        {
            title: '2+',
            value: '2'
        },
        {
            title: '3+',
            value: '3'
        },
        {
            title: '4+',
            value: '4'
        },
    ],
    "ru": [
        {
            title: 'Любой',
            value: '0'
        },
        {
            title: '1+',
            value: '1'
        },
        {
            title: '2+',
            value: '2'
        },
        {
            title: '3+',
            value: '3'
        },
        {
            title: '4+',
            value: '4'
        },
    ],
}


export const minBedrooms = [
    {
        title: 'Any',
        value: '0'
    },
    {
        title: 'Studio',
        value: 'studio'
    },
    {
        title: '1',
        value: '1'
    },
    {
        title: '2',
        value: '2'
    },
    {
        title: '3',
        value: '3'
    },
    {
        title: '4',
        value: '4'
    },
    {
        title: '4 and Above',
        value: '4+'
    }
]

export const maxBedrooms = [
    {
        title: 'Any',
        value: '0'
    },
    {
        title: '1',
        value: '1'
    },
    {
        title: '2',
        value: '2'
    },
    {
        title: '3',
        value: '3'
    },
    {
        title: '4',
        value: '4'
    },
    {
        title: '4 and Above',
        value: '4+'
    }
]

export const currencies = {
    "en": [
        {
            title: 'Thai Baht',
            value: 'thb',
            symbol: '฿'
        },
        {
            title: 'US Dollar',
            value: 'usd',
            symbol: '$'
        },
        {
            title: 'Euro',
            value: 'eur',
            symbol: '€'
        },
        {
            title: 'Chinese Yuan',
            value: 'rmb',
            symbol: 'CNY'
        },
        {
            title: 'GB Pound',
            value: 'gbp',
            symbol: '£'
        },
        {
            title: 'Australian Dollar',
            value: 'aud',
            symbol: 'AU$'
        },
        {
            title: 'Japanese Yen',
            value: 'jpy',
            symbol: '¥'
        }
    ],
    "th": [
        {
            title: 'บาท',
            value: 'thb',
            symbol: '฿'
        },
        {
            title: 'ดอลล่าร์',
            value: 'usd',
            symbol: '$'
        },
        {
            title: 'ยูโร',
            value: 'eur',
            symbol: '€'
        },
        {
            title: 'หยวน',
            value: 'rmb',
            symbol: 'CNY'
        },
        {
            title: 'ปอนด์',
            value: 'gbp',
            symbol: '£'
        },
        {
            title: 'ดอลลาร์ออสเตรเลีย',
            value: 'aud',
            symbol: 'AU$'
        },
        {
            title: 'เยนญี่ปุ่น',
            value: 'jpy',
            symbol: '¥'
        }
    ],
    "cn": [
        {
            title: '泰铢',
            value: 'thb',
            symbol: '฿'
        },
        {
            title: '人民币',
            value: 'rmb',
            symbol: 'CNY'
        },
        {
            title: '美金',
            value: 'usd',
            symbol: '$'
        },
        {
            title: '欧元',
            value: 'eur',
            symbol: '€'
        },
        {
            title: '英镑',
            value: 'gbp',
            symbol: '£'
        },
        {
            title: '澳元',
            value: 'aud',
            symbol: 'AU$'
        },
        {
            title: '日圆',
            value: 'jpy',
            symbol: '¥'
        }
    ],
    "ru": [
        {
            title: 'Тайский бат',
            value: 'thb',
            symbol: '฿'
        },
        {
            title: 'Доллар США',
            value: 'usd',
            symbol: '$'
        },
        {
            title: 'Евро',
            value: 'eur',
            symbol: '€'
        },
        {
            title: 'Китайский юань',
            value: 'rmb',
            symbol: 'CNY'
        },
        {
            title: 'Фунт стерлингов',
            value: 'gbp',
            symbol: '£'
        },
        {
            title: 'Австралийский доллар',
            value: 'aud',
            symbol: 'AU$'
        },
        {
            title: 'Японская иена',
            value: 'jpy',
            symbol: '¥'
        }
    ]

}

export const saleMinPrices = {
    "en": [
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        }
    ],
    "th": [
        {
            value: '0',
            nom: 'อื่นๆ'
        },
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        }
    ],
    "cn": [
        {
            value: '0',
            nom: '无限'
        },
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        }
    ],
    "ru": [
        {
            value: '0',
            nom: 'Любой'
        },
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        }
    ],
}

export const saleMaxPrices = {
    "en": [
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        },
        {
            value: '0',
            nom: 'Any'
        }
    ],
    "th": [
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        },
        {
            value: '0',
            nom: 'อื่นๆ'
        }
    ],
    "cn": [
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        },
        {
            value: '0',
            nom: '无限'
        }
    ],
    "ru": [
        {
            value: '500000',
            nom: '500,000'
        },
        {
            value: '1000000',
            nom: '1,000,000'
        },
        {
            value: '2000000',
            nom: '2,000,000'
        },
        {
            value: '3000000',
            nom: '3,000,000'
        },
        {
            value: '4000000',
            nom: '4,000,000'
        },
        {
            value: '5000000',
            nom: '5,000,000'
        },
        {
            value: '6000000',
            nom: '6,000,000'
        },
        {
            value: '7000000',
            nom: '7,000,000'
        },
        {
            value: '8000000',
            nom: '8,000,000'
        },
        {
            value: '9000000',
            nom: '9,000,000'
        },
        {
            value: '10000000',
            nom: '10,000,000'
        },
        {
            value: '15000000',
            nom: '15,000,000'
        },
        {
            value: '20000000',
            nom: '20,000,000'
        },
        {
            value: '25000000',
            nom: '25,000,000'
        },
        {
            value: '30000000',
            nom: '30,000,000'
        },
        {
            value: '40000000',
            nom: '40,000,000'
        },
        {
            value: '50000000',
            nom: '50,000,000'
        },
        {
            value: '0',
            nom: 'Любой'
        }
    ],
}

export const rentMinPrices = {
    "en": [
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        }
    ],
    "th":[
        {
            value: '0',
            nom: 'Any'
        },
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        }
    ],
    "cn":[
        {
            value: '0',
            nom: '无限'
        },
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        }
    ],
    "ru": [
        {
            value: '0',
            nom: 'Любой'
        },
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        }
    ],
}
export const rentMaxPrices = {
    "en": [
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '0',
            nom: 'Any'
        }
    ],
    "th": [
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '0',
            nom: 'Any'
        }
    ],
    "cn": [
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '0',
            nom: '无限'
        }
    ],
    "ru": [
        {
            value: '5000',
            nom: '5,000'
        },
        {
            value: '6000',
            nom: '6,000'
        },
        {
            value: '7000',
            nom: '7,000'
        },
        {
            value: '8000',
            nom: '8,000'
        },
        {
            value: '9000',
            nom: '9,000'
        },
        {
            value: '10000',
            nom: '10,000'
        },
        {
            value: '11000',
            nom: '11,000'
        },
        {
            value: '12000',
            nom: '12,000'
        },
        {
            value: '13000',
            nom: '13,000'
        },
        {
            value: '14000',
            nom: '14,000'
        },
        {
            value: '15000',
            nom: '15,000'
        },
        {
            value: '16000',
            nom: '16,000'
        },
        {
            value: '17000',
            nom: '17,000'
        },
        {
            value: '18000',
            nom: '18,000'
        },
        {
            value: '19000',
            nom: '19,000'
        },
        {
            value: '20000',
            nom: '20,000'
        },
        {
            value: '25000',
            nom: '25,000'
        },
        {
            value: '30000',
            nom: '30,000'
        },
        {
            value: '35000',
            nom: '35,000'
        },
        {
            value: '40000',
            nom: '40,000'
        },
        {
            value: '45000',
            nom: '45,000'
        },
        {
            value: '50000',
            nom: '50,000'
        },
        {
            value: '60000',
            nom: '60,000'
        },
        {
            value: '70000',
            nom: '70,000'
        },
        {
            value: '80000',
            nom: '80,000'
        },
        {
            value: '90000',
            nom: '90,000'
        },
        {
            value: '100000',
            nom: '100,000'
        },
        {
            value: '150000',
            nom: '150,000'
        },
        {
            value: '200000',
            nom: '200,000'
        },
        {
            value: '250000',
            nom: '250,000'
        },
        {
            value: '300000',
            nom: '300,000'
        },
        {
            value: '0',
            nom: 'Любой'
        }
    ],
}

export const serviceSaleTypes = {
    "en": [
        {
            title: 'Buy',
            value: 'buy'
        },
        {
            title: 'Rent',
            value: 'rent'
        }
    ],
    "th": [
        {
            title: 'Buy',
            value: 'buy'
        },
        {
            title: 'Rent',
            value: 'rent'
        }
    ],
    "cn": [
        {
            title: '买',
            value: 'buy'
        },
        {
            title: '租',
            value: 'rent'
        }
    ],
    "ru": [
        {
            title: 'Купить',
            value: 'buy'
        },
        {
            title: 'Снять',
            value: 'rent'
        }
    ],
}

export const preferredContact = [
    {
        name: 'Phone',
        value: 'phone'
    },
    {
        name: 'Email',
        value: 'email'
    },
    {
        name: 'LINE',
        value: 'line'
    },
    {
        name: 'Whatsapp',
        value: 'whatsapp'
    },
    {
        name: 'Wechat - 微信',
        value: 'wechat'
    },
    {
        name: 'Viber',
        value: 'viber'
    }
]

export const serviceCategoryTypes = {
    "en": [
        {
            title: 'Condo',
            value: 'condo'
        },
        {
            title: 'House',
            value: 'house'
        },
        {
            title: 'Land',
            value: 'land'
        },
        {
            title: 'Commercial',
            value: 'commercial'
        }
    ],
    "th": [
        {
            title: 'คอนโด',
            value: 'condo'
        },
        {
            title: 'บ้าน',
            value: 'house'
        },
        {
            title: 'ที่ดิน',
            value: 'land'
        },
        {
            title: 'เชิงพาณิชย์',
            value: 'commercial'
        }
    ],
    "cn": [
        {
            title: '公寓',
            value: 'condo'
        },
        {
            title: '别墅',
            value: 'house'
        },
        {
            title: '土地',
            value: 'land'
        },
        {
            title: '工商',
            value: 'commercial'
        }
    ],
    "ru": [
        {
            title: 'Квартиру',
            value: 'condo'
        },
        {
            title: 'Дом',
            value: 'house'
        },
        {
            title: 'Землю',
            value: 'land'
        },
        {
            title: 'Коммерческая',
            value: 'commercial'
        }
    ],
}

export const serviceCondoRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: 'Studio',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: 'สตูดิโอ',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'cn': [
        {
            title: '无限',
            value: '0'
        },
        {
            title: '开间户型',
            value: 'studio'
        },
        {
            title: '一',
            value: '1'
        },
        {
            title: '二',
            value: '2'
        },
        {
            title: '三',
            value: '3'
        },
        {
            title: '四',
            value: '4'
        },
        {
            title: '五和以上',
            value: '5'
        },
    ],
    'ru': [
        {
            title: 'Любой',
            value: '0'
        },
        {
            title: 'Студия',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],

}
export const condoRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: 'Studio',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: 'สตูดิโอ',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],
    'cn': [
        {
            title: '无限',
            value: '0'
        },
        {
            title: '开间户型',
            value: 'studio'
        },
        {
            title: '一',
            value: '1'
        },
        {
            title: '二',
            value: '2'
        },
        {
            title: '三',
            value: '3'
        },
        {
            title: '四',
            value: '4'
        },
        {
            title: '五和以上',
            value: '5'
        },
    ],
    'ru': [
        {
            title: 'Любой',
            value: '0'
        },
        {
            title: 'Студия',
            value: 'studio'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        },
    ],

}
export const houseRoomTypes = {
    'en': [
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'th': [
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'cn': [
        {
            title: '一',
            value: '1'
        },
        {
            title: '二',
            value: '2'
        },
        {
            title: '三',
            value: '3'
        },
        {
            title: '四',
            value: '4'
        },
        {
            title: '五和以上',
            value: '5'
        }
    ],
    'ru': [
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],

}
export const serviceHouseRoomTypes = {
    'en': [
        {
            title: 'Any',
            value: '0'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'th': [
        {
            title: 'ทั้งหมด',
            value: '0'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
    'cn': [
        {
            title: '无限',
            value: '0'
        },
        {
            title: '一',
            value: '1'
        },
        {
            title: '二',
            value: '2'
        },
        {
            title: '三',
            value: '3'
        },
        {
            title: '四',
            value: '4'
        },
        {
            title: '五和以上',
            value: '5'
        }
    ],
    'ru': [
        {
            title: 'Любой',
            value: '0'
        },
        {
            title: '1',
            value: '1'
        },
        {
            title: '2',
            value: '2'
        },
        {
            title: '3',
            value: '3'
        },
        {
            title: '4',
            value: '4'
        },
        {
            title: '5+',
            value: '5'
        }
    ],
}

export const serviceLandUnit = {
    "en": [
        {
            name: 'Rai',
            value: 'rai'
        },
        {
            name: 'Square meter',
            value: 'sqm'
        },
        {
            name: 'Square wa',
            value: 'sqw'
        },
        {
            name: 'Square feet',
            value: 'sqf'
        }
    ],
    "th": [
        {
            name: 'Rai',
            value: 'rai'
        },
        {
            name: 'Square meter',
            value: 'sqm'
        },
        {
            name: 'Square wa',
            value: 'sqw'
        },
        {
            name: 'Square feet',
            value: 'sqf'
        }
    ],
    "cn": [
        {
            name: '莱',
            value: 'rai'
        },
        {
            name: '平方米',
            value: 'sqm'
        },
        {
            name: '瓦',
            value: 'sqw'
        }
    ],
    "ru": [
        {
            name: 'Рай',
            value: 'rai'
        },
        {
            name: 'Квадратный метр',
            value: 'sqm'
        },
        {
            name: 'Квадратный ва',
            value: 'sqw'
        },
        {
            name: 'Квадратный фут',
            value: 'sqf'
        }
    ],
}