'use client'
import React, { useState, useEffect, useRef } from 'react'
import { useSession } from 'next-auth/react'
import Image from 'next/image'
import { AiOutlineLayout, AiFillHeart, AiOutlineHeart } from "react-icons/ai"
import { BiSolidBed, BiSolidBath } from "react-icons/bi"
import tailwindConfig from '@tailwind.config'
import { sendLike } from '@services/sendLike'
import CurrencySymbol from '@components/CurrencySymbol'
import { categoryTypes } from '@constants'
import { MdVilla } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import LinkComponent from './LinkComponent'
import { IoLocationOutline } from "react-icons/io5";

const ProjectCard = ({ item, url='/', imageWidth = 300, imageHeight = 200, newLimit, isLast, dictionary, lang }) => {
    const [showLikeBtn, setShowLikeBtn] = useState(false)
    const { data: session } = useSession()

    const cardRef = useRef();

    useEffect(() => {
        if (!cardRef?.current) return;

        const observer = new IntersectionObserver(([entry]) => {
            if (isLast && entry.isIntersecting) {
                newLimit();
                // nextPage()
                observer.unobserve(entry.target);
            }
        });

        observer.observe(cardRef.current);
    }, [isLast]);

    useEffect(() => {
        if (session && session.user !== null) {
            setShowLikeBtn(true)
        }
    }, [])

    return (
        <div ref={cardRef} className='w-full border-[1px] border-gray-200 rounded-xl'>
            <LinkComponent
                className='w-full'
                href={'/new-developments/' + item.slug}
                
            >
                <div className='relative w-full  aspect-[4/3] rounded-t-xl overflow-hidden'>
                    <Image
                        placeholder="blur"
                        blurDataURL={item.blur}
                        // className='object-fit'
                        className='object-cover'
                        src={item.cover}
                        // width={imageWidth}
                        // height={imageHeight}
                        fill={true}
                        alt={item.slug}
                    />
                    {item.publicTransport ? (
                        <div className='absolute flex flex-row flex-wrap bottom-0 left-0'>
                            {item.publicTransport.map((transport, index) => (
                                <div key={index} style={{ backgroundColor: transport.colorCode }} className={`rounded-sm px-1 text-light text-sm ml-1 mb-1`}>{transport.type.toUpperCase()} {transport.transportName?.[lang] ? transport.transportName[lang] : transport.stationName}</div>
                            ))}
                        </div>
                    ) : null}
                </div>
                
                {(item.type.toLowerCase() == 'completed') && (
                    <div className='absolute left-2 top-2 bg-third border-[1px] border-maincolor shadow-md shadow-maincolor/30 py-1 px-2 text-md rounded-full z-10'>
                        <h3>{dictionary.property.readyToMove}</h3>
                    </div>
                )}
                {(item.type.toLowerCase() == 'construction') && (
                    <div className='absolute left-2 top-2 bg-maincolor text-third border-[1px] border-third shadow-md shadow-third/30 py-1 px-2 text-md rounded-full z-10'>
                        <h3>{dictionary.property.underConstruction}</h3>
                    </div>
                )}
                {(item.type.toLowerCase() == 'offplan') && (
                    <div className='absolute left-2 top-2 bg-maincolor text-third border-[1px] border-third shadow-md shadow-third/30 py-1 px-2 text-md rounded-full z-10'>
                        <h3>{dictionary.property.offPlan}</h3>
                    </div>
                )}

                <div className='p-3'>
                    <div className='text-xl font-semibold text-maincolor line-clamp-1'>{item.name}</div>
                    {item.fromPrice !== undefined && (
                        <div className='text-xl font-semibold text-third'>{dictionary.property.from} <CurrencySymbol />{item.fromPrice}</div>
                    )}
                    {item.category == 'House' ? (
                        <div className='flex flex-row items-center mr-2 my-2'>
                            <MdVilla
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                            <span className='ml-1 text-maincolor'>{dictionary.property.house}</span>
                        </div>
                    ) : (
                        <div className='flex flex-row items-center mr-2 my-2'>
                            <FaBuilding
                                color={tailwindConfig.theme.extend.colors.secondary}
                            />
                            <span className='ml-1 text-maincolor'>{dictionary.property.condo}</span>
                        </div>
                    )}
                    <div className='flex flex-row items-center border-t-[1px] border-t-gray-300 py-1'>
                        <IoLocationOutline 
                            color={tailwindConfig.theme.extend.colors.mainhover} 
                        />
                        <span className='ml-1 text-mainhover'>{item.districts[0]?.subdistrictName?.[lang] ? item.districts[0].subdistrictName[lang] : item.districts[0]?.subdistrict}, {item.location}</span>
                    </div>
                </div>
            </LinkComponent >
        </div>
    )
}

export default ProjectCard