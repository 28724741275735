'use client'

import { useState } from 'react'
import { usePathname } from 'next/navigation'
import Link from 'next/link'
import { i18n } from '../i18n-config'
import { setCookie, getCookie, getCookies } from 'cookies-next';
import { BiWorld } from "react-icons/bi";
import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { FaGlobe } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";

export default function MobileLocaleSwitcher({ language, dictionary }) {
  const [currentLocale, setCurrentLocale] = useState(language)
  const [selected, setSelected] = useState(language)
  const [isOpen, setIsOpen] = useState(false)
  const pathName = usePathname()
  
  const redirectedPathName = (locale) => {
    const languages = ['en', 'cn', 'th', 'ru']

    if (!pathName) return '/'
    if (languages.includes(pathName.split('/')[1])) {
      const segments = pathName.split('/')
      segments[1] = locale
      return segments.join('/')
    } else {
      return `/${locale}` + pathName
    }
  }

  // console.log('hu '+ getCookie('NEXT_LOCALE'))
  // console.log('hu ' + currentLocale)


  const setLocaleCookie = (localeValue) => {
    setCookie('NEXT_LOCALE', localeValue);
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const langList = i18n.locales

  return (
    <>
      {langList.length > 2 ? (
        <div className="relative w-full">
        <p className='text-sm text-secondary'>{dictionary.language}</p>
        <Listbox>
            <div className="w-full">
                    <Listbox.Button className="w-full cursor-pointer text-maincolor text-center border-[1px] border-light border-solid p-1 flex items-center justify-between rounded-full mr-3">
                      <div className='flex flex-row items-center'>
                        <FaGlobe color='maincolor' />
                        <span className="ml-2 truncate text-maincolor">{dictionary[language]}</span>
                      </div>
                      <span><IoIosArrowDown /></span>
                    </Listbox.Button>
                    <Listbox.Options className="w-full py-1 bg-light overflow-hidden">
                    {i18n.locales.map((locale) => {
                      if (locale !== 'default') {
                        return (
                          <Link className='w-1/2' key={locale} onClick={() => setLocaleCookie(locale)} href={redirectedPathName(locale)}>
                              <div className={`w-full py-1 pl-2 rounded-full ${locale == language ? 'bg-maincolor text-light' : 'bg-light text-maincolor'}`}>
                                {dictionary[locale]} ({locale.toUpperCase()})
                            </div>
                          </Link>
                        ) 
                      }
                    })}
                    </Listbox.Options>
            </div>
            </Listbox>
        </div>
          ) : null}
    </>
  )
}